<template>
  <b-container>
    <b-row align-h="center">
      <b-col md="8">
        <b-card>
          <b-row class="my-1">
            <b-col md="8">
              <p class="font-medium-5 font-weight-bolder">#{{ form.code }}</p>
            </b-col>
            <b-col md="4" class="text-right">
              <b-badge :variant="statusVariant(this.status)" class="mb-1">
                {{ form.statusName }}
              </b-badge>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Jenis Sewa" label-for="type">
                <b-form-input id="type" v-model="form.typeName" readonly />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Periode Sewa" label-for="end-date">
                <b-form-input
                  id="end-date"
                  v-model="form.periodName"
                  readonly
                /> </b-form-group
            ></b-col>
          </b-row>
          <b-row>
            <b-col md="6" v-if="form.period === 2">
              <b-form-group label="Durasi Sewa (Bulan)" label-for="duration">
                <b-form-input
                  id="duration"
                  v-model="form.duration"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="form.period === 3">
              <b-form-group label="Durasi Sewa (Tahun)" label-for="duration">
                <b-form-input
                  id="duration"
                  v-model="form.duration"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Tanggal Mulai" label-for="start-date">
                <b-form-input
                  id="start-date"
                  v-model="form.startDate"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="form.period === 1">
              <b-form-group label="Tanggal Berakhir" label-for="end-date">
                <b-form-input
                  id="end-date"
                  v-model="form.endDate"
                  readonly
                /> </b-form-group
            ></b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Total Unit" label-for="total-unit">
                <b-form-input id="total_unit" v-model="form.total" readonly />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Deskripsi" label-for="description">
                <b-form-textarea
                  id="description"
                  placeholder="Deskripsi"
                  v-model="form.description"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Alamat Pengiriman"
                label-for="delivery-address"
              >
                <b-form-textarea
                  id="delivery-address"
                  placeholder="Alamat Pengiriman"
                  v-model="form.deliveryAddress"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Alamat Pengambilan"
                label-for="picking-address"
              >
                <b-form-textarea
                  id="picking-address"
                  placeholder="Alamat Pengambilan"
                  v-model="form.pickingAddress"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <label class="font-weight-bolder text-primary mb-1" for="product"
            >Informasi Pelanggan</label
          >
          <b-row>
            <b-col md="3"> Pelanggan <span class="float-right">:</span> </b-col>
            <b-col md="9">
              <span class="font-weight-bolder">{{ customer.name }}</span>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col md="3"> No Telp <span class="float-right">:</span> </b-col>
            <b-col md="9">
              <span class="font-weight-bolder">{{ customer.phone }}</span>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-success"
                size="sm"
                class="float-right"
                @click="toWhatsApp()"
              >
                Hubungi Pelanggan
              </b-button>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col md="3"> Email <span class="float-right">:</span> </b-col>
            <b-col md="9">
              <span class="font-weight-bolder">{{ customer.email }}</span>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col md="3"> Alamat <span class="float-right">:</span> </b-col>
            <b-col md="9">
              <span class="font-weight-bolder">{{ customer.address }}</span>
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            class="mt-3"
            block
          >
            Lihat Detail
          </b-button>
        </b-card>
        <b-card v-if="status === 3">
          <b-form-group label="Alasan Penolakan" label-for="reject_reason">
            <b-form-textarea
              id="reject_reason"
              placeholder="Alasan Penolakan"
              v-model="form.rejectReason"
              disabled
            />
          </b-form-group>
        </b-card>
        <b-card v-if="status == 2 || status == 4">
          <b-row>
            <b-col md="12" class="mb-1">
              <label for="details" class="text-primary font-weight-bold"
                >Informasi Penawaran</label
              >
            </b-col>
          </b-row>
          <b-row v-if="form.companyOffer === null">
            <b-col md="4"> Harga Penawaran</b-col>
            <b-col md="8" class="text-right font-weight-bolder">
              {{ formatRupiah(form.price) }}
            </b-col>
          </b-row>
          <hr v-if="form.companyOffer === null" />
          <b-row v-if="form.companyOffer !== null">
            <b-col md="4"> Harga Penawaran Awal</b-col>
            <b-col md="8" class="text-right font-weight-bolder">
              {{ formatRupiah(form.price) }}
            </b-col>
          </b-row>
          <hr v-if="form.companyOffer !== null" />
          <b-row v-if="form.companyOffer !== null">
            <b-col md="4"> Harga Penawaran Baru</b-col>
            <b-col md="8" class="text-right font-weight-bolder">
              {{ formatRupiah(form.companyOffer) }}
            </b-col>
          </b-row>
          <hr v-if="form.companyOffer !== null" />
          <b-row v-if="form.dealPrice !== null">
            <b-col md="4"> Harga yang disetujui</b-col>
            <b-col md="8" class="text-right font-weight-bolder">
              {{ formatRupiah(form.dealPrice) }}
            </b-col>
          </b-row>
          <hr v-if="form.dealPrice !== null" />
          <b-row>
            <b-col md="4"> Surat Penawaran </b-col>
            <b-col md="8" class="text-right font-weight-bolder">
              {{ form.file }}
            </b-col>
          </b-row>
          <hr v-if="form.note !== null" />
          <b-row v-if="form.note !== null">
            <b-col md="4"> Note </b-col>
            <b-col md="8" class="text-right font-weight-bolder">
              {{ form.note }}
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            class="mt-2"
            block
            v-b-modal.modal-update
          >
            Update
          </b-button>
        </b-card>
        <b-row v-if="status === 1">
          <b-col>
            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="danger"
                  class="mr-1"
                  block
                  v-b-modal.modal-reject
                >
                  Tolak
                </b-button>
              </b-col>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="success"
                  class="mr-1"
                  block
                  v-b-modal.modal-process
                >
                  Proses
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row v-if="status == 2">
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="danger"
              class="mr-1"
              block
              v-b-modal.modal-cancel
            >
              Batalkan
            </b-button>
          </b-col>
          <b-col v-if="status == 2 && form.accepted == 1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1"
              block
              @click="deal()"
            >
              Proses ke Kontrak
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="4">
        <b-card>
          <b-row>
            <b-col md="12" class="mb-1">
              <label for="details" class="text-primary font-weight-bold"
                >Katalog</label
              >
            </b-col>
            <b-col md="12" class="mb-2">
              <b-img
                id="image"
                :src="catalog.image"
                fluid
                alt="Unit Image"
                v-b-modal.modal-lg
              />
              <b-modal
                id="modal-lg"
                ok-only
                ok-title="close"
                centered
                size="lg"
                title="Large Modal"
                class="text-center"
              >
                <b-img
                  id="image"
                  :src="catalog.image"
                  width="1000px"
                  fluid
                  alt="Unit image"
                />
              </b-modal>
            </b-col>
            <b-col md="12">
              <b-row>
                <b-col md="4"> Kode </b-col>
                <b-col md="8" class="text-right font-weight-bolder">
                  {{ catalog.code }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col md="4"> Nama </b-col>
                <b-col md="8" class="text-right font-weight-bolder">
                  {{ catalog.name }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col md="4"> Kategori </b-col>
                <b-col md="8" class="text-right font-weight-bolder">
                  {{ catalog.category }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-card v-if="status == 2 && form.customerOffer != null">
          <b-row>
            <b-col cols="2">
              <span class="mr-1 text-success">
                <feather-icon icon="AlertCircleIcon" size="55" />
              </span>
            </b-col>
            <b-col>
              <p class="mb-25">Pelanggan mengajukan penawaran sebesar:</p>
              <h3 class="font-weight-bolder">
                {{ formatRupiah(form.customerOffer) }}
              </h3>
            </b-col>
          </b-row>
        </b-card>
        <b-card v-if="status == 2 && form.accepted == 1">
          <b-row>
            <b-col cols="2">
              <span class="mr-1 text-success">
                <feather-icon icon="CheckCircleIcon" size="55" />
              </span>
            </b-col>
            <b-col>
              <p class="mb-25">Pelanggan Telah Setuju dengan Penawaran:</p>
              <h3 class="font-weight-bolder">
                {{ formatRupiah(form.companyOffer) }}
              </h3>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <!-- modal change password -->
      <b-modal
        id="modal-reject"
        title="Penolakan Permintaan"
        ok-title="Kirim"
        cancel-variant="outline-secondary"
        centered
        hide-footer
      >
        <validation-observer ref="loginValidation">
          <b-form ref="form" @submit.prevent>
            <b-form-group
              :state="errors"
              label="Alasan Penolakan"
              label-for="reject"
              invalid-feedback="Alasan penolakan tidak boleh kosong"
            >
              <validation-provider
                #default="{ errors }"
                name="reject"
                rules="required"
              >
                <b-form-textarea
                  id="reject"
                  name="reject"
                  type="text"
                  v-model="form.reject_reason"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Alasan Penolakan"
                  required="required"
                />
                <small class="text-danger">{{ errors[0] || errMessage }}</small>
              </validation-provider>
            </b-form-group>

            <b-row>
              <b-col>
                <b-button
                  class="mt-1 mb-2"
                  type="submit"
                  variant="danger"
                  block
                  @click="reject()"
                >
                  Kirim
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>

      <b-modal
        id="modal-cancel"
        title="Pembatalan Permintaan"
        ok-title="Kirim"
        cancel-variant="outline-secondary"
        centered
        hide-footer
      >
        <validation-observer ref="loginValidation">
          <b-form ref="form" @submit.prevent>
            <b-form-group
              :state="errors"
              label="Alasan Pembatalan"
              label-for="cancel"
              invalid-feedback="Alasan pembatalan tidak boleh kosong"
            >
              <validation-provider
                #default="{ errors }"
                name="cancel"
                rules="required"
              >
                <b-form-textarea
                  id="cancel"
                  name="cancel"
                  type="text"
                  v-model="form.cancelReason"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Alasan Pembatalan"
                  required="required"
                />
                <small class="text-danger">{{ errors[0] || errMessage }}</small>
              </validation-provider>
            </b-form-group>

            <b-row>
              <b-col>
                <b-button
                  class="mt-1 mb-2"
                  type="submit"
                  variant="danger"
                  block
                  @click="cancel()"
                >
                  Kirim
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>

      <b-modal
        id="modal-update"
        title="Proses Penawaran"
        ok-title="Kirim"
        cancel-variant="outline-secondary"
        centered
        hide-footer
      >
        <validation-observer ref="loginValidation">
          <b-form ref="form" @submit.prevent>
            <b-row>
              <b-col>
                <b-form-group label="Harga Penawaran Baru" label-for="offering">
                  <cleave
                    id="offering"
                    placeholder="Harga Penawaran Baru"
                    v-model="companyOffer"
                    class="form-control"
                    :raw="false"
                    :options="number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Note" label-for="Note">
                  <b-form-textarea
                    id="note"
                    name="note"
                    type="text"
                    v-model="form.note"
                    placeholder="Note"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="File" label-for="File">
                  <b-form-file
                    v-model="file"
                    placeholder="Upload file disini..."
                    drop-placeholder="Upload file disini..."
                    id="extension"
                    accept=".pdf"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-button
                  class="mt-1 mb-2"
                  type="submit"
                  variant="success"
                  block
                  @click="update()"
                >
                  Perbaharui
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>

      <b-modal
        id="modal-process"
        title="Proses Penawaran"
        ok-title="Kirim"
        cancel-variant="outline-secondary"
        centered
        hide-footer
      >
        <validation-observer ref="loginValidation">
          <b-form ref="form" @submit.prevent>
            <b-row>
              <b-col>
                <b-form-group label="Harga Penawaran" label-for="offering">
                  <cleave
                    id="offering"
                    placeholder="Harga Penawaran"
                    v-model="price"
                    class="form-control"
                    :raw="false"
                    :options="number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Note" label-for="note">
                  <b-form-textarea
                    id="note"
                    name="note"
                    type="text"
                    v-model="form.note"
                    placeholder="Note"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="File" label-for="File">
                  <b-form-file
                    v-model="file"
                    placeholder="Upload file disini..."
                    drop-placeholder="Upload file disini..."
                    id="extension"
                    accept=".pdf"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-button
                  class="mt-1 mb-2"
                  type="submit"
                  variant="success"
                  block
                  @click="process()"
                >
                  Proses
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- end modal change password -->
    </b-row>
  </b-container>
</template>

<script>
import {
  BFormTextarea,
  BBadge,
  BImg,
  BFormTimepicker,
  BFormDatepicker,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BContainer,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import flatPickr from "vue-flatpickr-component";
import { $themeConfig } from "@themeConfig";
import Cleave from "vue-cleave-component";
import moment from "moment";

export default {
  components: {
    ToastificationContent,
    flatPickr,
    Cleave,
    BFormTextarea,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    BImg,
    BFormTimepicker,
    BFormDatepicker,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    draggable,
    Prism,
    BContainer,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        code: "",
        startDate: "",
        endDate: "",
        description: "",
        total: null,
        price: null,
        file: null,
        rejectReason: "",
        companyOffer: null,
        customerOffer: null,
        dealPrice: null,
        accepted: 0,
        note: null,
        type: null,
        duration: 0,
        period: 0,
        typeName: "",
        periodName: "",
        deliveryAddress: null,
        pickingAddress: null,
        cancelReason: "",
      },
      catalog: {
        name: "",
        code: "",
        image: "",
        category: "",
      },
      customer: {
        name: "",
        email: "",
        phone: "",
        address: "",
      },
      status: "",
      photo: "",
      permissions: [],
      errors: "",
      errMessage: "",
      file: null,
      price: 0,
      companyOffer: 0,
      number: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
      note: null,
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        1: "light-warning",
        2: "light-primary",
        3: "light-danger",
        4: "light-success",
        5: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },
  setup() {
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  created() {
    this.getAllPermissions();
    this.getDetails();
  },
  methods: {
    formatRupiah(money) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(money);
    },
    moment(date) {
      return moment(date).format("DD-MM-YYYY, hh:mm");
    },
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getDetails() {
      const id = this.$route.params.id;
      this.$http
        .get("request/quotation/" + id)
        .then((response) => {
          let $data = response.data.data;

          this.status = $data.status;
          this.form.startDate = $data.start_date;
          this.form.endDate = $data.end_date;
          this.form.description = $data.description;
          this.form.total = $data.total_unit;
          this.form.price = $data.price;
          this.form.file = $data.file;
          this.form.rejectReason = $data.reject_reason;
          this.form.code = $data.code;
          this.form.companyOffer = $data.company_offer;
          this.form.customerOffer = $data.customer_offer;
          this.form.dealPrice = $data.deal_price;
          this.form.accepted = $data.customer_accept;
          this.form.duration = $data.duration;
          this.form.period = $data.period;
          this.form.type = $data.type;
          this.form.statusName = $data.status_name;
          this.form.periodName = $data.period_name;
          this.form.typeName = $data.type_name;
          this.form.deliveryAddress = $data.delivery_address;
          this.form.pickingAddress = $data.picking_address;
          this.form.note = $data.note;

          let $catalog = $data.catalog;
          const { baseUrl } = $themeConfig.app;
          this.catalog.image = baseUrl;
          this.catalog.image += "image/catalog/";
          this.catalog.image += $catalog.image;
          this.catalog.name = $catalog.name;
          this.catalog.code = $catalog.code;
          this.getCategory($catalog.category_id);

          let $customer = $data.customer;
          this.customer.id = $customer.id;
          this.customer.name = $customer.name;
          this.customer.phone = $customer.phone;
          this.customer.email = $customer.email;

          console.log($data.customer_accept);
          console.log(this.customerAccept);
          let $address = $data.address;
          this.customer.address =
            $address.address +
            ", " +
            $address.village +
            ", " +
            $address.district +
            ", " +
            $address.city +
            ", " +
            $address.province +
            ", " +
            $address.country +
            " " +
            $address.postal_code;
        })
        .catch((errors) => {
          console.log(errors.response);
        });
    },
    getCategory($id) {
      this.$http
        .get("unit/category/" + $id)
        .then((response) => {
          let $data = response.data.data;
          this.catalog.category = $data.name;
        })
        .catch((errors) => {
          console.log(errors.response);
        });
    },
    update() {
      const id = this.$route.params.id;
      var newPrice = this.companyOffer;

      if (newPrice != null) {
        newPrice = newPrice.replace(/\,/g, "");
      }
      const formData = new FormData();
      if (this.file != null) {
        formData.append("file", this.file);
      }
      formData.append("note", this.form.note);
      formData.append("company_offer", newPrice);

      this.$http
        .post("request/quotation/" + id + "/update", formData)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Permintaan penawaran diperbaharui",
              variant: "success",
            },
          });
          location.href = "/sales/quotation/" + id;
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    process() {
      const id = this.$route.params.id;
      var newPrice = this.price;

      if (newPrice != null) {
        newPrice = newPrice.replace(/\,/g, "");
      }
      const formData = new FormData();
      if (this.file != null) {
        formData.append("file", this.file);
      }
      formData.append("note", this.form.note);
      formData.append("price", newPrice);

      this.$http
        .post("request/quotation/" + id + "/process", formData)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Permintaan penawaran diproses",
              variant: "success",
            },
          });
          location.href = "/sales/quotation/" + id;
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    finish() {
      const id = this.$route.params.id;
      this.$http
        .post("request/quotation/" + id + "/finish")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Permintaan penawaran selesai",
              variant: "success",
            },
          });
          location.href = "/sales/quotations";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    deal() {
      const id = this.$route.params.id;
      this.$http
        .post("request/quotation/" + id + "/deal", {
          type: 2,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Permintaan penawaran selesai",
              variant: "success",
            },
          });
          location.href = "/sales/quotations";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    reject() {
      const id = this.$route.params.id;
      this.$http
        .post("request/quotation/" + id + "/reject", {
          reject_reason: this.form.rejectReason,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Permintaan penawaran ditolak",
              variant: "danger",
            },
          });
          location.href = "/sales/quotations";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    cancel() {
      const id = this.$route.params.id;
      this.$http
        .post("request/quotation/" + id + "/cancel", {
          cancel_reason: this.form.cancelReason,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Permintaan penawaran dibatalkan",
              variant: "danger",
            },
          });
          location.href = "/sales/quotations";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    toWhatsApp() {
      const phone = this.customer.phone;
      var uri = "https://wa.me/62" + phone.substring(1);
      window.open(uri, "_blank", "noreferrer");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>